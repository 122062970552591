.inputText{
  position: relative;
  .inputTitle{
    position: absolute;
    top: -8px;
    left: 8px;
    padding: 0 2px;
    font-family: notoMedium;
    background: #fff;
    color: #000;
    b{
      color: #E63312;
    }
  }
  .titleDisable {
    background: #fff;
  }
  .inputfield{
    border: 1px solid #C6CFDB;
    outline: 0;
    border-radius: 0;
    min-height: 50px;
    height: 100%;
    width: 100%;
    padding: 0 8px;
    font-family: notoRegular;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.64px;
  }
  .text{
    color: #676767;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    background: #6767671A;
  }
  .error{
    font-family: notoRegular;
    letter-spacing: -0.32px;
    color: red !important;
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    line-height: 12px;
    left: 0;
    max-width: 300px;
    width: 300px;
  }
}
