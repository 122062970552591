$primary: #172663;
$lightsand: #f6f3f2;
$slide-width: 135px;
$slide-height: 50px;
$slide-right-space: 35px;
$focus-shadow: 0 0 2px 2px #51a7e8;

.time-line {
  font-family: 'notoRegular';
  &-tabs {
    position: relative;
    margin-bottom: 65px;
    .viewport {
      overflow: hidden;
      height: $slide-height;
      position: relative;
      margin: 0 40px;
      .wrap-slides {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        list-style: none;
        transition: 0.3s;
        &:after {
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 6px;
          border-top: 2px solid $primary;
          transform: translateX(-100%);
          transition: ease-in-out 0.5s;
          content: '';
        }
        @for $i from 0 through 50 {
          &[data-active="#{$i}"] {
            &:after {
              transform: translateX(calc(-100% + (#{$slide-width} - #{$slide-right-space}) + (#{$slide-width} * #{$i})));
            }
          }
        }
        .slide {
          position: relative;
          flex-shrink: 0;
          width: $slide-width;
          height: 50px;
          &:last-child {
            width: #{$slide-width + $slide-right-space};
            .button-year {
              right: #{$slide-right-space * 2};
            }
          }
          &:before {
            display: block;
            width: calc(100% - 3px);
            height: 1px;
            position: absolute;
            left: 3px;
            bottom: 6px;
            border-top: 2px dashed #dfdfdf;
            content: '';
          }
          .button-year {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            position: absolute;
            right: $slide-right-space;
            bottom: 0;
            z-index: 1;
            font-weight: 500;
            transform: translateX(50%);
            &:focus {
              box-shadow: $focus-shadow;
              outline: none;
            }
            .year {
            }
            .dot {
              width: 12px;
              height: 12px;
              border: 2px solid $primary;
              background-color: #fff;
              border-radius: 100%;
            }
          }
          &.active {
            font-size: 22px;
            color: $primary;
            transition: 0.2s;
            .button-year {
              .year {
  
              }
              .dot {
                background-color: $primary;
              }
            }
          }
        }
      }
    }
    .arrow {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 31px;
      background-image: url("/Images/ickeyboardarrowright48px.svg");
      background-size: 25%;
      background-position: 55% center;
      background-repeat: no-repeat;
      background-color: $primary;
      border-radius: 100%;
      &:focus {
        box-shadow: $focus-shadow;
        outline: none;
      }
      &.prev {
        left: 0;
        transform: rotate(180deg);
      }
      &.next {
        right: 0;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    background-color: $lightsand;
    &:focus {
      box-shadow: $focus-shadow;
      outline: none;
    }
    .content-list {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      padding: 20px 70px;
      font-size: 16px;
      line-height: 1.5;
      a {
        color: inherit;
      }
    }
  }
}