@font-face {
  font-family: HDharmonyL;
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/HDharmonyL.woff2") format("woff2"),
    url("../assets/fonts/HDharmonyL.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: HDharmonyM;
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/HDharmonyM.woff2") format("woff2"),
    url("../assets/fonts/HDharmonyM.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: HDharmonyB;
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/HDharmonyB.woff2") format("woff2"),
    url("../assets/fonts/HDharmonyB.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: notoRegular;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/NotoSansKR-Regular.otf");
}

@font-face {
  font-family: notoMedium;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/NotoSansKR-Medium.otf");
}
