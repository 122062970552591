.inputText {
  position: relative;
  .inputMask {
    width: 100%;
    border: 1px solid #c6cfdb;
    outline: 0;
    border-radius: 0;
    height: 50px;
    // width: 262px;
    padding: 0 8px;
    font-family: notoRegular;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.64px;
    color: #000;
    -webkit-appearance: none !important;
  }

  //   .disableField {
  //     background-color: #6767671a;
  //   }

}

// .inputTitle {
//   font-family: notoMedium;
//   position: absolute;
//   top: -8px;
//   left: 8px;
//   padding: 0 2px;
//   background: #fff;
//   color: #000;
//   z-index: 100;
// }
