.site_map_wrap {
  max-width: 996px;
  margin: 0 auto;
  background: #fff;
  margin-top: 32px;
  border: 1px solid #c8d1dc;

  .sitemap-wrap {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 49px 20px 60px 20px;

    @media screen and (max-width: 996px) {
      padding: 40px 16px 60px 16px;
    }

    @media screen and (max-width: 576px) {
      padding: 24px 36px;
    }

    .sitemapList {
      width: 100%;
      @media screen and (max-width: 1199px) {
        width: 100%;
      }
      .sitemapList_wrap {
        width: 100%;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          // @media screen and (max-width: 996px) {
          //   padding: 0 !important;
          // }
          @media screen and (max-width: 996px), screen and (min-width: 2000px) {
            padding: 0 !important;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
          }
          @media screen and (max-width: 375px) {
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
          }

          .langActive {
            padding: 0 40px !important;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
            @media screen and (max-width: 500px) {
              text-align: center;
              width: 42% !important;
              margin-left: 4px;
            }
            @media screen and (max-width: 375px) {
              text-align: center;
              width: 42% !important;
              margin-left: 4px;
            }

            &:nth-last-child(1) {
              @media screen and (max-width: 500px) {
                margin-top: 32px;
              }
              .title {
                &::before {
                  @media screen and (max-width: 768px) {
                    left: -30px !important;
                  }
                  @media screen and (max-width: 375px) {
                    left: -30px !important;
                  }
                }
              }
            }

            &:nth-last-child(2) {
              .title {
                &::before {
                  @media screen and (max-width: 768px) {
                    left: 0px !important;
                  }
                  @media screen and (max-width: 500px) {
                    left: 14px !important;
                  }
                  @media screen and (max-width: 375px) {
                    left: 0px !important;
                  }
                }
              }
            }

            &:nth-last-child(3) {
              .title {
                &::before {
                  @media screen and (max-width: 768px) {
                    left: 0px !important;
                  }
                  @media screen and (max-width: 500px) {
                    left: 9px !important;
                  }
                  @media screen and (max-width: 375px) {
                    left: 3px !important;
                  }
                }
              }
            }
          }

          .wrap-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 60px;
            @media screen and (max-width: 996px), screen and (min-width: 2000px) {
              padding: 0 45px !important;
              align-items: flex-start;
              width: 100%;
            }
            @media screen and (max-width: 576px), screen and (min-width: 2000px) {
              padding: 0 15px !important;
              align-items: flex-start;
              padding: 0 !important;
              width: 100%;
            }
            @media screen and (max-width: 375px) {
              align-items: left;
              padding: 0 !important;
              width: 100%;
            }

            &:nth-last-child(1) {
              @media screen and (max-width: 500px) {
                margin-top: 32px;
              }
            }
            &:not(:nth-child(1)) {
              @media screen and (max-width: 996px), screen and (min-width: 2000px) {
                margin-top: 32px;
              }
            }

            .title {
              color: #000000;
              font-size: 21px;
              line-height: 21px;
              font-family: HDharmonyM;
              letter-spacing: -0.8px;
              position: relative;

              &::before {
                content: "";
                background: url("/Images/arrowyellow.png") center no-repeat;
                position: absolute;
                top: 6px;
                left: -12px;
                width: 8px;
                height: 9px;

                @media screen and (max-width: 996px) {
                  top: 5px;
                }

                @media screen and (max-width: 375px) {
                  left: -12px !important;
                }
              }

              &:hover {
                color: #172663;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              text-align: center;
              @media screen and (max-width: 996px), screen and (min-width: 2000px) {
                text-align: left;
              }
              a {
                text-decoration: none;
                margin-top: 24px;

                span {
                  color: #676767;
                  font-size: 16px;
                  line-height: 16px;
                  text-align: left;
                  font-family: notoRegular;
                  letter-spacing: -0.64px;

                  &:hover {
                    color: #b49108;
                  }
                }
              }
            }
          }
        }

        .row_1 {
          padding-left: 0;
          padding-right: 65px;
          text-align: center;

          .title {
            &::before {
              left: -7px;
            }
          }

          @media screen and (max-width: 375px) {
            width: 36%;
          }
        }

        .row_2 {
          padding: 0 65px;
          text-align: center;

          .title {
            &::before {
              left: 2px;

              @media screen and (max-width: 375px) {
                left: -2px !important;
              }
            }
          }
        }

        .row_4 {
          padding-left: 65px;
          text-align: center;

          .title {
            &::before {
              left: -14px;

              @media screen and (max-width: 375px) {
                left: -6px !important;
              }
            }
          }

          @media screen and (max-width: 375px) {
            > span {
              margin-top: 32px;
            }

            width: 36%;
          }
        }
      }
    }
  }
}
