/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
// ol, ul {
// 	list-style: none;
// }
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-brand {
  cursor: pointer;
}

// Custom Perfect Scroll Bar
.ps__rail-y {
  right: 0 !important;
  left: unset !important;
}

.scrollbar-container {
  height: auto !important;
}

option {
  width: 200px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

// Fix css of react-bootstrap-table
.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.react-bootstrap-table-pagination .col-md-6 {
  width: 50%;
}

.react-bootstrap-table th[data-row-selection] {
  width: 5% !important;
  text-align: center;
}

.react-bootstrap-table input[type="checkbox"] {
  cursor: pointer;
}

.react-bootstrap-table td {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
}

.select div[aria-hidden="true"] {
  padding: 4px !important;
}

input.form-control {
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.form-control:focus {
  border-color: #9ed4ee;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 163, 234, 0.6);
}
.ant-select-dropdown-menu-item {
  span {
    font-family: notoRegular;
    color: #000;
  }
}
.ant-modal-confirm {
  .ant-modal-content {
    border-radius: 0;
    .ant-modal-body {
      border-radius: 0;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          font-family: notoRegular;
        }
        .ant-modal-confirm-btns {
          .ant-btn {
            outline: none;
            border-radius: 0;
            background: #666666;
            span {
              font-family: notoRegular;
              color: #fff;
            }
          }
          .ant-btn-primary {
            border: none;
            box-shadow: none;
            background: #172663;
            &:focus {
              box-shadow: 0 0 2px 2px #51a7e8;
            }
          }
        }
      }
    }
  }
}
a,
button,
span.ant-upload {
  &:focus {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
  &:hover {
    box-shadow: none;
  }
  &:visited {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
  }
  @media screen and (max-width: 576px) {
    &:focus {
      box-shadow: none;
    }
  }
}

// li.dot-label {
//   &:focus {
//     outline: none !important;
//     border: none;
//     box-shadow: 0 0 2px 2px #51a7e8;
//   }
//   &:hover {
//     box-shadow: none
//   }
//   &:visited {
//     box-shadow: none;
//   }
//   &:active {
//     box-shadow: none;
//   }
// }

.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  font-family: notoRegular;
}
.menuWrap .wrap-content .content .content1:active ~ .dropdownList {
  transform: translateY(0);
  opacity: 1;
  -webkit-transform: translateY(0);
  z-index: 3 !important;
  visibility: visible !important;
}

// Responsive css

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  * {
    font-size: 11px;
    word-break: keep-all !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  * {
    font-size: 12px;
    word-break: keep-all !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  * {
    font-size: 13px;
    word-break: keep-all !important;
  }

  .sidebar,
  .sidebar .nav,
  .sidebar-nav {
    width: 200px !important;
  }

  .sidebar-minimized .sidebar,
  .sidebar-minimized .sidebar .nav,
  .sidebar-minimized .sidebar-nav {
    width: 50px !important;
  }

  html:not([dir="rtl"]) .sidebar {
    margin-left: -200px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  * {
    font-size: 14px;
  }

  .sidebar,
  .sidebar .nav,
  .sidebar-nav {
    width: 220px !important;
  }

  .sidebar-minimized .sidebar,
  .sidebar-minimized .sidebar .nav,
  .sidebar-minimized .sidebar-nav {
    width: 50px !important;
  }

  html:not([dir="rtl"]) .sidebar {
    margin-left: -220px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  * {
    font-size: 14px;
  }

  .sidebar,
  .sidebar .nav,
  .sidebar-nav {
    width: 220px !important;
  }

  .sidebar-minimized .sidebar,
  .sidebar-minimized .sidebar .nav,
  .sidebar-minimized .sidebar-nav {
    width: 50px !important;
  }

  html:not([dir="rtl"]) .sidebar {
    margin-left: -220px;
  }
}

//fix input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input {
  -webkit-user-select: text;
  -webkit-appearance: none;
}
input[type="text"] {
  -webkit-appearance: textfield;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// style scrollbar
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a[disabled] {
  background: #eaedf2 !important;
  cursor: not-allowed !important;
  pointer-events: auto;
}

span.ant-radio + * {
  font-family: notoRegular !important;
}

.ant-select-dropdown-menu-item-selected {
  background: #e6f7ff;
}
.ant-select-dropdown-menu-item-active {
  background: #e6f7ff;
  outline: 1px dotted #000;
}
.skip-link {
  position: absolute;
  top: -9999em;
  z-index: 999999;
  width: 100%;
  background-color: #013874;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.skip-link:focus {
  top: 0;
  padding: 12px 0;
  color: #fff;
}
.blind {
  opacity: 0;
  text-indent: -999em;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.hTagHidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}
