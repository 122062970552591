#main{
  display: table;
  width: 100%;
  height: 10vh;
  text-align: center;
}

.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

button {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.ant-modal-wrap:focus {
  border: 2px solid #51a7e8
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}
